import { PageContext, FormContext } from '../pageContext';
import { useContext } from "react";

function Step3() {
    const [step, setStep] = useContext(PageContext);
    const [form, setFrom] = useContext(FormContext);
    // console.log(form);
    // console.log(JSON.stringify(form));
    if(form.plan === undefined) {
        setFrom({ ...form, plan: {} });
    }
    
    const saveForm = (key, value) => {
        let obj = {};
        obj[key] = value;
        if(form.plan !== undefined) {
            obj = { ...form.plan, ...obj };
        }
        setFrom({ ...form, plan: obj });
    }
    const changeCardNumber = (e) => {
      const cardNum = e.target.value
      .replace(/\D/g, "")
      .replace(/(\s)/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .replace(/\s*$/, "")
      .substring(0, 19);
      saveForm("card_num", cardNum);
    };

    return (
        <div>
            <header>
                <div className={"container"}>
                    <div className={"banner"}><img src="images/petclub.jpg" /></div>
                </div>
            </header>
            <div className={"content"}>
                <div className={"container"}>
                    <form className={"form"}>
                        <h2><span>請選擇您的計劃</span></h2>
                        <div className={"form-group"}>
                            <div className={"col-4"}>
                                <input
                                    type="radio"
                                    id="true1"
                                    name="plan"
                                    className={"regular-radio"}
                                    value="49"
                                    checked={((form.plan || {}).plan || '') === '49'}
                                    onChange={(e) => saveForm('plan', e.target.value)}
                                />
                                <label for="true1" className={"Privacy_Policy"}>免費入會</label>
                            </div>
                            <div className={"col-4"}>
                                <input
                                    type="radio"
                                    id="true2"
                                    name="plan"
                                    className={"regular-radio"}
                                    value="32"
                                    checked={((form.plan || {}).plan || '') === '32'}
                                    onChange={(e) => saveForm('plan', e.target.value)}
                                />
                                <label for="true2" className={"Privacy_Policy"}
                                >Pet Pet會員<br />($1登記)</label
                                >
                            </div>
                            <div className={"col-4"}>
                                <input
                                    type="radio"
                                    id="true3"
                                    name="plan"
                                    className={"regular-radio"}
                                    value="33"
                                    checked={((form.plan || {}).plan || '') === '33'}
                                    onChange={(e) => saveForm('plan', e.target.value)}
                                />
                                <label for="true3" className={"Privacy_Policy"}
                                >VIP會員 <br />($1登記, $39月費)</label
                                >
                            </div>
                            <div className={"col-4"}>
                                <input
                                    type="radio"
                                    id="true4"
                                    name="plan"
                                    className={"regular-radio"}
                                    value="34"
                                    checked={((form.plan || {}).plan || '') === '34'}
                                    onChange={(e) => saveForm('plan', e.target.value)}
                                />
                                <label for="true4" className={"Privacy_Policy"}
                                >慈善機構 <br />(送寵物救護服務終生保障)</label
                                >
                            </div>
                            <div className={"col-4"}>
                                <input
                                    type="radio"
                                    id="true5"
                                    name="plan"
                                    className={"regular-radio"}
                                    value="35"
                                    checked={((form.plan || {}).plan || '') === '35'}
                                    onChange={(e) => saveForm('plan', e.target.value)}
                                />
                                <label for="true5" className={"Privacy_Policy"}
                                >合作商戶<br />(送寵物救護服務終生保障)</label
                                >
                            </div>
                        </div>
                        {
                            (((form.plan || {}).plan || null) !== '49' && ((form.plan || {}).plan || null) !== null) && (
                                <div>
                                    <h2><span>請選擇付款方式</span></h2>
                                    <div className={"form-group"}>
                                        { ((false && (form.plan || {}).plan || null) === '32') && (
                                            <div className={ "col-12" }>
                                                <input
                                                    type="radio"
                                                    id="pay1"
                                                    name="pay"
                                                    checked={(form.plan || {}).payment === 'pay1'}
                                                    onChange={(e) => saveForm('payment', 'pay1')}
                                                    className={ "regular-radio" }
                                                />
                                                <label for="pay1" className={ "Privacy_Policy" }>ApplePay/ Google Pay</label>
                                            </div>
                                        )}
                                        <div className={"col-12"}>
                                            <input type="radio" id="pay2" name="pay" className={"regular-radio"}
                                                    checked={(form.plan || {}).payment === 'pay2'}
                                                    onChange={(e) => saveForm('payment', 'pay2')} />
                                            <label for="pay2" className={"Privacy_Policy"}>信用卡</label>
                                        </div>
                                        { ((form.plan || {}).payment || null) === 'pay2' && (
                                            <div className={"col-12"}>
                                                <div className={"label_a"}>卡號</div>
                                                <input type="tel" className={"form-control"} placeholder="請輸入卡號"
                                                    value={((form.plan || {}).card_num || '')}
                                                    onInput={changeCardNumber} />
                                            </div>
                                        )}
                                    </div>
                                    { ((form.plan || {}).payment || null) === 'pay2' && (
                                        <div className={"form-group"}>
                                            <div className={"col-6"}>
                                                <div className={"label_a"}>有效期</div>
                                                <input type="text" className={"form-control"} placeholder="MM/YY"
                                                    value={((form.plan || {}).card_exp || '')}
                                                    onInput={(e) => {
                                                        if(e.nativeEvent.inputType == 'insertText'){
                                                            if(e.target.value.length > 5){
                                                                return;
                                                            }
                                                            if(e.target.value.length == 2){
                                                                e.target.value = e.target.value + '/';
                                                            }
                                                        }
                                                        saveForm('card_exp', e.target.value);
                                                    }} />
                                            </div>
                                            <div className={"col-6"}>
                                                <div className={"label_a"}>信用卡驗證碼</div>
                                                <input
                                                    type="tel"
                                                    className={"form-control"}
                                                    placeholder="請輸入驗證碼"
                                                    value={((form.plan || {}).card_cvc || '')}
                                                    onInput={(e) => saveForm('card_cvc', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        }

                        <div className={"form-group"}>
                            <div className={"submit col-6"} onClick={() => {
                                setStep('step2');
                            }}><a href="javascript:void(0);">上一步</a></div>

                            <div className={"submit col-6"} onClick={() => {
                                if(!((form.plan || {}).plan || '')){
                                    alert('請選擇計劃');
                                    return;
                                }
                                if(!['49'].includes((form.plan || {}).plan)){
                                    if(!((form.plan || {}).payment || '')){
                                        alert('請選擇付款方式');
                                        return;
                                    }
                                    if((form.plan || {}).payment == 'pay2'){
                                        if(((form.plan || {}).card_num || '').replace(/ /g, '').trim().length != 16){
                                            alert('請輸入正確的信用卡卡號');
                                            return;
                                        }
                                        let card_exp = ((form.plan || {}).card_exp || '').split('/');
                                        if(card_exp.length != 2 || card_exp[0].length != 2 || card_exp[1].length != 2){
                                            alert('請輸入正確的信用卡有效期');
                                            return;
                                        }
                                        if(((form.plan || {}).card_cvc || '').trim().length != 3 && ((form.plan || {}).card_cvc || '').trim().length != 4){
                                            alert('請輸入正確的信用卡驗證碼');
                                            return;
                                        }
                                    }
                                }
                                setStep('processing');
                            }}><a href="javascript:void(0);">提交</a></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Step3;