import { PageContext, FormContext } from '../pageContext';
import { useContext } from "react";
import 'antd/dist/antd.css';

function Step1() {
    const [step, setStep] = useContext(PageContext);
    const [form, setFrom] = useContext(FormContext);

    if(form.reg === undefined) {
        setFrom({ ...form, reg: {} });
    }

    const saveForm = (key, value) => {
        let obj = {};
        obj[key] = value;
        if(form.reg !== undefined) {
            obj = {...form.reg, ...obj};
        }
        setFrom({ ...form, reg: obj });
    }
    let promoter = window.location.pathname.replace('/', '').trim() || '';

    return (
        <div>
            <header>
                <div className={"container"}>
                    <div className={"banner"}><img src="images/petclub.jpg" /></div>
                </div>
            </header>
            <div className={"content"}>
                <div className={"container"}>
                    <form className={"form"}>
                        <h2><span>註冊</span></h2>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>姓名</div>
                                <input
                                    type="text"
                                    className={"form-control"}
                                    placeholder="請輸入姓名"
                                    value={(form.reg || {}).name || ''}
                                    onChange={(e) => saveForm('name', e.target.value)}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>電郵</div>
                                <input type="email" className={"form-control"} placeholder="電郵"
                                    value={(form.reg || {}).email || ''}
                                    onChange={(e) => saveForm('email', e.target.value)} />
                            </div>
                        </div>

                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>聯絡電話</div>
                                <input
                                    className={"form-control"}
                                    type="tel"
                                    placeholder="請輸入聯絡電話"
                                    value={(form.reg || {}).tel || ''}
                                    onChange={(e) => saveForm('tel', e.target.value)}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>地址</div>
                                <input
                                    className={"form-control"}
                                    type="text"
                                    placeholder="請輸入地址"
                                    value={(form.reg || {}).address || ''}
                                    onChange={(e) => saveForm('address', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-6"}>
                                <div className={"label_a"}>密碼</div>
                                <input
                                    type="password"
                                    className={"form-control"}
                                    placeholder="請輸入密碼"
                                    value={(form.reg || {}).password || ''}
                                    onChange={(e) => saveForm('password', e.target.value)}
                                />
                            </div>
                            <div className={"col-6"}>
                                <div className={"label_a"}>確認密碼</div>
                                <input
                                    type="password"
                                    className={"form-control"}
                                    placeholder="請輸入密碼"
                                    value={(form.reg || {}).confirm || ''}
                                    onChange={(e) => saveForm('confirm', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={"form-group"}>
                            <div className={"col-12"}>
                                <input type="checkbox" id="true" className={"regular-radio"}
                                    onClick={(e) => saveForm('agreed', e.target.value)} />
                                <label htmlFor="true" className={"Privacy_Policy"}>我同意及接受有關條款及細則，包括收集個人資料聲明及相關條款。</label>
                            </div>
                        </div>
                        { (false && promoter === '') && (
                            <div className={"form-group"}>
                                <div className={"col-12"}>
                                    <div className={"label_a"}>介紹參展商</div>
                                    <select className={"form-control"}
                                        onClick={(e) => saveForm('promoter', e.target.value)} defaultValue="">
                                        <option disabled value="">請選擇</option>
                                        <option value="Tolam (Sino-HK)Trading Co ltd">Tolam (Sino-HK)Trading Co ltd</option>
                                        <option value="Petogan">Petogan</option>
                                        <option value="TLC">TLC</option>
                                        <option value="Faith Max">Faith Max</option>
                                        <option value="Paws">Paws</option>
                                        <option value="HVOC">HVOC</option>
                                        <option value="Mongolian">Mongolian</option>
                                        <option value="Pet Holiday">Pet Holiday</option>
                                        <option value="Vivet">Vivet</option>
                                        <option value="Charm">Charm</option>
                                        <option value="DDY">DDY</option>
                                        <option value="Germkiller">Germkiller</option>
                                        <option value="Joy pet">Joy pet</option>
                                        <option value="LAP">LAP</option>
                                        <option value="Love Adopt">Love Adopt</option>
                                        <option value="HKSDC">HKSDC</option>
                                        <option value="FHSE">FHSE</option>
                                        <option value="HKAAC">HKAAC</option>
                                        <option value="HKGDA">HKGDA</option>
                                        <option value="CCAC">CCAC</option>
                                        <option value="HKSEDS">HKSEDS</option>
                                        <option value="HKHERP">HKHERP</option>
                                        <option value="Doctor Pet">Doctor Pet</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className={"submit"} onClick={() => {
                            if(!((form.reg || {}).name || '').trim().length > 0){
                                alert('請輸入姓名');
                                return;
                            }

                            if (!Boolean((form.reg || {}).email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g)) || ((form.reg || {}).email.split('@')[0] || "").length > 64) {
                                alert('請輸入有效電郵');
                                return;
                            }
                            if(!((form.reg || {}).tel || '').trim().length > 0){
                                alert('請輸入聯絡電話');
                                return;
                            }
                            if(!((form.reg || {}).address || '').trim().length > 0){
                                alert('請輸入地址');
                                return;
                            }
                            if(!((form.reg || {}).password || '').trim().length > 0){
                                alert('請輸入密碼');
                                return;
                            }
                            if((form.reg || {}).password !== (form.reg || {}).confirm){
                                alert('密碼不一致');
                                return;
                            }
                            if((form.reg || {}).agreed === 'on'){
                                setStep('step2');
                            } else {
                                alert('請勾選同意條款');
                            }
                        }}><a href="javascript:void(0);">下一步</a></div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Step1;