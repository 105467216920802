import React from "react";
import Step1 from './page/step1';
import Step2 from './page/step2';
import Step3 from './page/step3';
import Processing from './page/processing';
import Success from './page/success';
import { PageContext } from './pageContext';
import { useContext } from "react";

function MiniRoute() {
    const [step] = useContext(PageContext);
    switch (step) {
        case "step1":
            return (<Step1 />);
        case "step2":
            return (<Step2 />);
        case "step3":
            return (<Step3 />);
        case "processing":
            return (<Processing />);
        case "success":
            return (<Success />);
        default:
            return (<h1>404 Not Found</h1>);
    }
}
export default MiniRoute;